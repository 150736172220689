import {HttpInterceptorFn} from '@angular/common/http';
import {environment} from "@environments/environment";

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const token = localStorage.getItem('token') || 'CMV-PATIENT-TOKEN-' + Date.now();
  if (!token) return next(req);

  const isCmvApi = req.url.includes(environment.cmvBaseApi);

  if (isCmvApi) {
    const setAuthHeader = {headers: req.headers.set('Authorization', `${token}`)};
    const headers = req.clone(setAuthHeader);
    return next(headers);
  }

  return next(req);
};
