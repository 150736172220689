import {inject} from "@angular/core";
import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';

import {throwError} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';

import {AppState, notification} from "@interfaces/ui-state.interface";
import {HttpEventType} from "@shared/enums/http-event-type.enum";
import {endpointMessages} from "@shared/interceptors/http-endpoint-messages.const";

import {UiService} from "@services/ui.service";

export const httpInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const ui = inject(UiService);

  const method = req.method;
  const matchingPattern = Object.keys(endpointMessages).find(pattern => new RegExp(pattern).test(req.url));

  if (matchingPattern && endpointMessages[matchingPattern][method]) {
    const message = endpointMessages[matchingPattern][method].start;
    ui.state.update(current => ({...current, isLoading: true, message, status: AppState.LOADING}));
    ui.presentToast(ui.state()).then();
  }

  return next(req).pipe(
    filter(req => req.type !== HttpEventType.Sent),
    // retry({count: 5, delay: 1500}),
    tap(() => {
      if (matchingPattern && endpointMessages[matchingPattern][method]) {
        const message = endpointMessages[matchingPattern][method].end;
        ui.state.set({
          message,
          isLoading: false,
          status: AppState.SUCCESS,
          notification: notification.success
        });
        ui.presentToast(ui.state()).then();
      }
    }),
    catchError((error) => {
      if (matchingPattern && endpointMessages[matchingPattern][method]) {

        const message = endpointMessages[matchingPattern][method].error;
        ui.state.set({message, status: AppState.ERROR, isLoading: false, notification: notification.error, error})
        ui.presentToast(ui.state()).then();

      }

      return throwError(() => error);

    })
  );
};

