import {CSP_NONCE, enableProdMode} from '@angular/core';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {bootstrapApplication} from '@angular/platform-browser';
import {IonicRouteStrategy, provideIonicAngular} from '@ionic/angular/standalone';
import localeCL from '@angular/common/locales/es-CL'

import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withPreloading
} from '@angular/router';

import {routes} from './app/app.routes';
import {AppComponent} from './app/app.component';
import {environment} from '@environments/environment';
import {registerLocaleData} from "@angular/common";
import {authInterceptor} from "@shared/interceptors/auth.interceptor";
import {httpInterceptor} from "@shared/interceptors/http.interceptor";
import {NonceService} from "@services/nonce.service";

const nonceService = new NonceService();
if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeCL);
// Definir el nonce en el contexto global
// globalThis.anyNonceValue = btoa(Math.random().toString()).substring(0, 16); // Genera un nonce de 16 caracteres
bootstrapApplication(AppComponent, {
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: CSP_NONCE,
      useValue: nonceService.getNonce()
    },
    provideHttpClient(withInterceptors([authInterceptor, httpInterceptor])),
    provideIonicAngular(),
    provideRouter(routes, withComponentInputBinding(), withPreloading(PreloadAllModules)),
  ],
});
