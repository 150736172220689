import {Component} from '@angular/core';
import {IonApp, IonRouterOutlet} from '@ionic/angular/standalone';
import {Amplify} from 'aws-amplify';
import {AmplifyAuthenticatorModule, AuthenticatorService} from "@aws-amplify/ui-angular";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, AmplifyAuthenticatorModule],


})
export class AppComponent {
  constructor(public authenticator: AuthenticatorService) {
    Amplify.configure({
      Auth: {Cognito: environment.cognitoUserPool}
    });
  }
}
