export const GES_FINANCIERS = {

  dev: [
    "Isapre Banmédica",
    "Isapre Chuquicamata",
    "Isapre Colmena",
    "Isapre Consalud",
    "Isapre Cruz Blanca",
    "Isapre Cruz del Norte",
    "Isapre Esencial",
    "Isapre Fundación",
    "Isapre Fusat",
    "Isapre ISalud",
    "Isapre Nueva MasVida",
    "Isapre Río Blanco",
    "Isapre San Lorenzo",
    "Isapre Vida Tres",
  ],
  tst: [
    "Isapre Banmédica",
    "Isapre Chuquicamata",
    "Isapre Colmena",
    "Isapre Consalud",
    "Isapre Cruz Blanca",
    "Isapre Cruz del Norte",
    "Isapre Esencial",
    "Isapre Fundación",
    "Isapre Fusat",
    "Isapre ISalud",
    "Isapre Nueva MasVida",
    "Isapre Río Blanco",
    "Isapre San Lorenzo",
    "Isapre Vida Tres",
  ],
  prod: [
    "Isapre Banmédica",
    "Isapre Chuquicamata",
    "Isapre Colmena",
    "Isapre Consalud",
    "Isapre Cruz Blanca",
    "Isapre Cruz del Norte",
    "Isapre Esencial",
    "Isapre Fundación",
    "Isapre Fusat",
    "Isapre ISalud",
    "Isapre Nueva MasVida",
    "Isapre Río Blanco",
    "Isapre San Lorenzo",
    "Isapre Vida Tres",
  ]
}
