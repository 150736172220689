import {inject} from '@angular/core';
import {CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {fetchUserAttributes} from '@aws-amplify/auth';


const checkAuth = async (): Promise<boolean> => {
  const router = inject(Router);
  try {
    const user = await fetchUserAttributes();
    if (user) {
      return true;
    } else {
      await router.navigate(['/autenticar']);
      return false;
    }
  } catch (error) {
    await router.navigate(['/autenticar']);
    return false;
  }
};

export const authGuard: CanActivateFn = async () => checkAuth();
export const childAuthGuard: CanActivateChildFn = async () => checkAuth();

