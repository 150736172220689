import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NonceService {
  private nonceValue: string;

  constructor() {
    this.nonceValue = btoa(Math.random().toString()).substring(0, 16); // Genera el nonce
  }

  getNonce(): string {
    return this.nonceValue;
  }
}
